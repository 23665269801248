import { Component, OnInit } from '@angular/core';
import { NavigationList } from '@app-models/navigationList';
import { AuthService } from '@app-services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { getProfileURL } from '@app-utils/helper';
@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
  drawerButtonOption = {
    icon: 'menu',
    onClick: () => (this.isDrawerOpen = !this.isDrawerOpen),
  };

  currentUser: any;
  profileImage: string;
  navigation: NavigationList[] = [
    { id: 1, text: 'Dashboard', route: '/dashboard', icon: 'home' },
    { id: 2, text: 'Events', route: '/events', icon: 'event' },
    { id: 3, text: 'Sessions', route: '/sessions', icon: 'product' },
    { id: 4, text: 'Gear Sales', route: '/gear-sales', icon: 'product' },
    { id: 5, text: 'Customers', route: '/customers', icon: 'group' },
    { id: 6, text: 'Orders', route: '/orders', icon: 'cart' },
    //{ id: 7, text: 'Reports', route: '/reports', icon: 'chart' },
    { id: 8, text: 'Waitlists', route: '/waitlists', icon: 'user' },
    { id: 11, text: 'Transfers', route: '/transfers', icon: 'user' },
    { id: 12, text: 'Swim Teams', route: '/swim-team', icon: 'user' },
    {
      id: 9,
      text: 'Email Templates',
      route: '/email-templates',
      icon: 'email',
    },
    {
      id: 10,
      text: 'Scheduled Emails',
      route: '/scheduled',
      icon: 'clock',
    },
  ];

  selectedMenu = [];
  selectedOpenMode = 'shrink';
  selectedPosition = 'left';
  selectedRevealMode = 'slide';
  isDrawerOpen = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.profileImage = getProfileURL(this.currentUser.displayName);
    const routerState = '_routerState';
    const { url } = this.activatedRoute.snapshot[routerState];
    const navItem = this.navigation.find((x) => x.route === url);
    if (navItem) {
      this.selectedMenu = [navItem.id];
    }
  }

  onMenuItemClick(e): void {
    this.router.navigate([e.itemData.route]);
  }

  onLogout(): void {
    this.authService.logout();
  }
}
